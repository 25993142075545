import { Pages, LocalizedPages, PageKind, LanguageAlternate } from "@app/shared/types/pages"

export const pages: Pages = {
  en: {
    "about-us": "about-us",
    agent: "agent",
    blog: "blog",
    "buy-house": "houses-for-sale",
    compliance: "compliance",
    "contact-us": "contact",
    "digital-signature": "digital-signature",
    "digital-signature-otp": "digital-signature-otp",
    "digital-signature-privacy": "digital-signature-privacy",
    faq: "faq",
    faqs: "faqs",
    guides: "guides",
    "legal-entities": "legal-entities",
    "legal-mentions": "legal-mentions",
    match: "match",
    mortgage: "mortgage",
    "perfect-match": "trova-un-acquirente-perfetto",
    press: "press",
    priority: "go-priority",
    "sell-house": "sell-house",
    "terms-and-conditions": "terms-and-conditions",
    "virtual-tools": "virtual-tools",
  },
  es: {
    "about-us": "about-us",
    agent: "agent",
    blog: "blog",
    "buy-house": "casas-en-venta",
    compliance: "compliance",
    "contact-us": "contacto",
    "digital-signature": "firma-electronica",
    "digital-signature-otp": "digital-signature-otp",
    "digital-signature-privacy": "digital-signature-privacy",
    faq: "faq",
    faqs: "faqs",
    guides: "guia",
    "legal-entities": "datos-de-la-sociedad",
    "legal-mentions": "legal-mentions",
    match: "",
    mortgage: "hipotecas",
    "perfect-match": "encuentra-al-comprador-perfecto",
    press: "press",
    priority: "go-priority",
    "sell-house": "vender-casa",
    "terms-and-conditions": "terminos-y-condiciones",
    "virtual-tools": "virtual-tools",
  },
  fr: {
    "about-us": "a-propos",
    agent: "devenir-agent",
    blog: "blog",
    "buy-house": "acheter-bien",
    compliance: "compliance",
    "contact-us": "contact",
    "digital-signature": "digital-signature",
    "digital-signature-otp": "digital-signature-otp",
    "digital-signature-privacy": "digital-signature-privacy",
    faq: "faq",
    faqs: "faqs",
    guides: "guides",
    "legal-entities": "legal-entities",
    "legal-mentions": "mentions-legales",
    match: "match",
    mortgage: "mortgage",
    "perfect-match": "trova-un-acquirente-perfetto",
    press: "presse",
    priority: "go-priority",
    "sell-house": "vendre-bien",
    "terms-and-conditions": "conditions-generales",
    "virtual-tools": "virtual-tools",
  },
  it: {
    "about-us": "about-us",
    agent: "diventa-agente",
    blog: "blog",
    "buy-house": "case-in-vendita",
    compliance: "compliance",
    "contact-us": "contattaci",
    "digital-signature": "firma-elettronica",
    "digital-signature-otp": "firma-elettronica-otp",
    "digital-signature-privacy": "firma-elettronica-privacy",
    faq: "faq",
    faqs: "faqs",
    guides: "guide",
    "legal-entities": "dati-societari",
    "legal-mentions": "legal-mentions",
    match: "come-mettere-in-vendita-casa-trovare-acquirente-offerta",
    mortgage: "mutui",
    "perfect-match": "trova-un-acquirente-perfetto",
    press: "press",
    priority: "go-priority",
    "sell-house": "vendi-casa",
    "terms-and-conditions": "termini-e-condizioni",
    "virtual-tools": "virtual-tools",
  },
}

export const create =
  (locale: LocaleWithDefault) =>
  (kind: PageKind): LocalizedPages =>
    locale === "__default" ? pages["en"][kind] : pages[locale][kind]

export const kind =
  (locale: Locale) =>
  (page: string): PageKind | undefined => {
    const localizedPages = pages[locale]
    return localizedPages
      ? (Object.keys(localizedPages).find((key) => localizedPages[key as PageKind] === page) as PageKind)
      : undefined
  }

export const DEFAULT_LOCALE = "__default"
export type LocaleWithDefault = Locale | typeof DEFAULT_LOCALE

export const seo = (basePath: string) => (kind?: PageKind) => ({
  alternates: (locales: Array<LocaleWithDefault>): LanguageAlternate[] => {
    const fallbackLocale = locales.includes("en") ? "en" : (locales[0] as Locale)
    return [
      ...(locales.filter((l) => l !== DEFAULT_LOCALE && l !== "es") as Array<Locale>).map((locale) => ({
        // now also filtering 'es' to remove 'es' hreflang
        // href: 'basePath/it/'
        href: basePath
          .concat("/".concat(locale))
          .concat(kind ? "/".concat(pages[locale][kind]) : "")
          .concat("/"),
        hrefLang: locale,
      })),
      {
        href: basePath
          .concat("/".concat(fallbackLocale))
          .concat(kind ? "/".concat(pages[fallbackLocale][kind]) : "")
          .concat("/"),
        hrefLang: "x-default",
      },
    ]
  },
  canonical: (locale: LocaleWithDefault): string =>
    basePath
      .concat("/".concat(locale))
      .concat(kind ? "/".concat(pages[locale === DEFAULT_LOCALE ? "en" : locale][kind]) : "")
      .concat("/"),
})

export const lisaUrl = (locale: Locale) =>
  ({
    en: "houses-for-sale",
    es: "casas-en-venta",
    fr: "acheter-bien",
    it: "case-in-vendita",
  }[locale])

export const countryLocales = (locales: LocaleWithDefault[]) =>
  locales?.filter((locale) => locale !== DEFAULT_LOCALE) as Locale[]

export const lisaSuggestedUrl = (lang: string) =>
  `${process.env.NEXT_PUBLIC_LISA_URL}${lang === "en" ? "" : `/${lang}`}/suggested/onboarding/`

export const lisaCompleteUrl = (lang: Locale) =>
  `${process.env.NEXT_PUBLIC_LISA_URL}${lang === "en" ? "" : `/${lang}`}/${lisaUrl(lang)}/`
